﻿import * as React from 'react';
import ReactDOM from 'react-dom';

const Component = () => <p>Hello from React!</p>;

class WebComponent extends HTMLElement {
    connectedCallback() {
        const root = this.attachShadow({ mode: 'open' });
        const mountPoint = document.createElement('div');
        root.appendChild(mountPoint);
        hello(mountPoint);
    }
}

export default function hello(renderElement: HTMLElement) {
    ReactDOM.createPortal(<Component />, renderElement);
}

(window as any).renderReact = hello;
window.customElements.define("react-hello", WebComponent);


(window as any).addMessageListener = (dotNetInstance: any) => {
    window.parent.postMessage('chatwoot-dashboard-app:fetch-info', '*');
    window.addEventListener("message", (event: MessageEvent) => {
        const dominio = event.origin.split('//')[1].split(':')[0];

        if (!isJSONValid(event.data)) {
            return;
        }
        const eventData = JSON.parse(event.data);
        //console.log(JSON.stringify(eventData));
        dotNetInstance.invokeMethodAsync("ReceiveMessageAsync", JSON.stringify(eventData), dominio, MyHourLocal());
    });
};
function isJSONValid(jsonString: string): boolean {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
}

function MyHourLocal(): string {
    const dataHoraAtual = new Date();

    // Obtém a diferença em minutos entre o horário local e o UTC
    const diferencaUTC = dataHoraAtual.getTimezoneOffset();

    // Calcula a diferença em horas e minutos
    const horasDiferenca = Math.floor(diferencaUTC / 60);
    const minutosDiferenca = diferencaUTC % 60;

    // Formata a diferença para exibição (adiciona zeros à esquerda, se necessário)
    const sinal = horasDiferenca < 0 ? "+" : "-";
    const horasFormatadas = Math.abs(horasDiferenca) < 10 ? "0" + Math.abs(horasDiferenca) : Math.abs(horasDiferenca);
    const minutosFormatados = minutosDiferenca < 10 ? "0" + minutosDiferenca : minutosDiferenca;

    // Cria uma string que mostra a diferença no formato +/-HH:MM
    const diferencaFormatada = sinal + horasFormatadas + ":" + minutosFormatados;
    return diferencaFormatada;
}


(window as any).addEventListener("message", async (event: MessageEvent) => {
    if (!isJSONValid(event.data)) {
        return null;
    }
    const eventData = JSON.parse(event.data);
    return eventData;
});
